import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'
import Header from './header'
import './layout.scss'

const Layout = ({ pageTitle, children }) => (
  <div style={{ marginLeft: '2em', marginRight: '2em' }}>
    <div className="container">
      <Header pageTitle={pageTitle} />
      <>{children}</>
      <footer className="footer">
        <div className="columns">
          <div className="column has-text-centered">
            <Link to="/AboutThisSite">about this site</Link>
          </div>
        </div>
      </footer>
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
