import PropTypes from 'prop-types'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { IoLogoTwitter } from 'react-icons/io'
import { IoLogoLinkedin } from 'react-icons/io'
import { IoLogoGithub } from 'react-icons/io'
import { IoLogoInstagram } from 'react-icons/io'
import { IoLogoYoutube } from 'react-icons/io'
import { IoMdBeer } from 'react-icons/io'
import styles from './header.module.scss'

const Header = ({ pageTitle }) => (
  <div className="columns" style={{ marginTop: '10px' }}>
    <div className="column is-narrow is-hidden-mobile">
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "IMG_1717.jpg" }) {
              childImageSharp {
                fixed(width: 128, height: 128) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <figure className="image is-128x128">
            <Link to="/">
              <Img
                fixed={data.placeholderImage.childImageSharp.fixed}
                className={styles.main_logo}
              />
            </Link>
          </figure>
        )}
      />
    </div>
    <div className="column">
      <div className="columns">
        <div className="column is-two-thirds has-text-centered-mobile">
          <h1
            className="title is-size-3 is-size-5-mobile"
            style={{ marginLeft: '30px' }}
          >
            <img
              src="/icons/icon-48x48.png"
              className={`${
                styles.mini_logo
              } is-hidden-desktop is-hidden-tablet`}
              alt="Logo"
            />

            {pageTitle}
          </h1>
        </div>
        <div className="column has-text-right has-text-centered-mobile">
          <a href="https://twitter.com/mike_moran/" title="me on twitter">
            <span className="icon is-large is-size-2">
              <IoLogoTwitter />
            </span>
          </a>
          <a
            href="https://www.linkedin.com/in/mrmikemoran/"
            title="me on LinkedIn"
          >
            <span className="icon is-large is-size-2">
              <IoLogoLinkedin />
            </span>
          </a>
          <a href="https://github.com/mikemoraned/" title="me on GitHub">
            <span className="icon is-large is-size-2">
              <IoLogoGithub />
            </span>
          </a>
          <a
            href="https://www.youtube.com/channel/UCGhaYkBRWPm4aCtHH53LgaQ"
            title="me on YouTube"
          >
            <span className="icon is-large is-size-2">
              <IoLogoYoutube />
            </span>
          </a>
          <a
            href="https://www.instagram.com/mikemoraned/"
            title="me on Instagram"
          >
            <span className="icon is-large is-size-2">
              <IoLogoInstagram />
            </span>
          </a>
          <a href="https://untappd.com/user/mikemoran/" title="me on Untappd">
            <span className="icon is-large is-size-2">
              <IoMdBeer />
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
)

Header.propTypes = {
  pageTitle: PropTypes.string,
}

Header.defaultProps = {
  pageTitle: ``,
}

export default Header
